<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Storage Classification Tests (BATF)" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The following tests are used to determine the Bureau of Alcohol,
            Tobacco, and Firearms (BATF) storage compatibilities and
            quantity-distance separations for a given substance. The internal
            ignition test is performed as a screening test to determine whether
            a material is an explosive substance. If the material is an
            explosive substance, the No.8 cap test is used to characterize the
            material into the categories of either high explosive (cap
            sensitive) or low explosive (not cap sensitive).
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="Internal Ignition" />
        <Paragraph
          ><p>
            This test is designed to determine the response of an explosive to
            rapidly rising temperatures and pressures. The sample, with an
            initial temperature of 25°C, is loaded into a schedule 80 pipe with
            forged steel end caps. A black powder bag igniter is inserted into
            the center of the pipe and the leads are sealed with epoxy resin.
            The igniter is fired and the results are assessed. Either the pipe
            or at least one of the end caps must be fragmented into at least two
            distinct pieces for a positive result.
          </p>
          <p>
            The test is considered negative (the material passes) if the pipe is
            merely split open or the caps are blown off in one piece. Three
            trials are performed unless a positive result occurs earlier. The
            test determines if a material will explode or detonate when ignited
            under confinement.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="No. 8 Cap Test" />
        <Paragraph
          ><p>
            The No. 8 cap test is used to determine susceptibility of explosives
            to detonation from the energy delivered by a No. 8 electric blasting
            cap. The test consists of a sample approximately 60 in3 in size
            placed in a cardboard tube (liquid samples are placed in a
            polypropylene bottle). The tube is placed on top of a steel witness
            plate with a No. 8 cap inserted at the top of the cardboard tube.
            Sample detonation is determined by examining the witness plate the
            criteria for detonation is that the witness plate is torn or
            penetrated.
          </p>
          <p>
            The explosive is considered a high explosive if it fails the No. 8
            cap test and a low explosive if it passes.
          </p>
        </Paragraph>
      </div>
    </div>
    <Paragraph></Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Storage Classification Tests",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS performs storage classification tests (BATF) to determine compatibilities and quantity distance separations. These tests include Internal Ignition and No. 8 Cap."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
